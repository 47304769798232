import React from "react";

export default function Phone(props) {
  var style =
    "max-w-md mx-auto lg:w-8/12 lg:w-full shadow-xl p-1 lg:p-2 sm:rounded-2g rounded-3xl lg:rounded-4xl backdrop-blur-sm";

  if (props.tilt) {
    style += "transform rotate-12 -skew-y-3";
  }

  return (
    <div className={style}>
      <video
        class="object-fill sm:rounded-2g rounded-3xl lg:rounded-4xl"
        poster={props.poster}
        autoPlay
        muted
        defaultMuted
        playsinLine
        preload="auto"
        loop>
        <source src={props.webm} type="video/webm" />
        <source src={props.mp4} type="video/mp4" />
      </video>
    </div>
  );
}
