import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Phone from "../../components/device/phone";
import Highlight from "../text/highlight";
import appPoster from "../../images/screenshot/dyslexia-ai/dashboard.webp";
import appVideoMp4 from "../../assets/dyslexia-ai/dashboard_compressed.mp4";
import appVideoWebm from "../../assets/dyslexia-ai/dashboard_compressed.webm";


export default function Hero() {
  return (
    <header className="flex-col items-center py-10 lg:py-20 bg-background1">
      <svg class="h-full container absolute inset-0 overflow-visible" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30%" cy="20%" r="1000" stroke="#fcfcfc08" stroke-width="10" fill="none"/>
        <circle cx="20%" cy="60%" r="500" stroke="#fcfcfc08" stroke-width="10" fill="none"/>
      </svg>
      <h1 className="hidden">
        Round Tower - Creative Indie App Studio, based in Ireland. We develop
        Android and iOS apps, websites &amp; marketing campaigns
      </h1>

      <section className="container mx-auto max-w-screen-lg lg:flex lg:justify-evenly">
        <div className="container flex-col m-auto">
          <div className="m-w-md">
            <StaticImage
              src="./../../images/logo_no_padding.webp"
              alt="Round Tower Indie App Studio Logo"
              className="mx-auto h-0 object-contain invisible lg:visible lg:h-40"
              objectFit={"contain"}
              loading="eager"
              placeholder="none"
            />
          </div>
          <h2 className="font-main font-semibold max-w-lg p-5 mt-20 lg:mt-8 mx-auto text-white text-3xl md:text-4xl lg:text-center">
            Creative Indie App Studio, from the little Island of Ireland
          </h2>
        </div>

        <div className="mt-10">
          <Phone
            id={"hero-video-1"}
            poster={appPoster}
            mp4={appVideoMp4}
            webm={appVideoWebm}
            tilt={true}
          />
        </div>
      </section>

      <p className="max-w-2xl font-main font-regular p-8 mx-auto m-16 text-white text-2xl text-right md:text-4xl md:text-center leading-loose">
        We <Highlight text="dream up," /> <Highlight text="design, " /> and{" "}
        <Highlight text="develop" />apps loved by <Highlight text="humans, " />  across the
        world, and right here at home.
      </p>

    </header>
  );
}
