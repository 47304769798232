import React, { useState } from "react";
import { Link } from "gatsby"
import posed from "react-pose";
import logo from "./../../images/logo_no_padding.webp";
import menu_open from "./../../images/menu-open.svg";
import menu_close from "./../../images/menu-close.svg";
import Social from "./../social/social";
import GetInTouchButton from "../button/get-in-touch";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const NavTransition = posed.section({
  visible: {
    pointerEvents: "all",
    x: "0%",
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 100,
    transition: {
      opacity: { ease: "easeOut", duration: 300 },
      default: { ease: "linear", duration: 500 },
    },
  },
  hidden: {
    pointerEvents: "none",
    x: "-100%",
    transition: {
      default: { ease: "easeOut", duration: 500 },
    },
  },
});

const ItemFade = posed.div({
  visible: {
    pointerEvents: "all",
    x: "0%",
    opacity: 1,
    transition: {
      opacity: { ease: "easeOut", duration: 300 },
    },
  },
  hidden: {
    pointerEvents: "none",
    x: "-30%",
    opacity: 0,
  },
});

export default function Nav(props) {
  const [isOpen, setOpen] = useState(false);

  const navMobile = (
    <ul className="font-title font-bold text-white text-2xl flex flex-col px-4 lg:flex-row mx-auto justify-evenly text-center leading-loose z-30">
      <li className="lg:text-center my-2">
        <ItemFade>
          <Link to={"/"} className="z-50 pointer-events-auto">
            Home
          </Link>
        </ItemFade>
      </li>
      <li className="lg:text-center my-2">
        <ItemFade>
          <Link to="/blog" className="z-50 pointer-events-auto">
            Blog
          </Link>
        </ItemFade>
      </li>
      <li className="lg:text-center my-2">
        <ItemFade>
          <OutboundLink
            href="https://dyslexia.ai"
            target="_blank"
            rel="noreferrer"
            className="font-comfortaa font-semibold my-2 px-6 py-2 z-50 pointer-events-auto">
            dyslexia.ai
          </OutboundLink>
        </ItemFade>
      </li>
      <li className="lg:text-center my-2 font-poppins font-medium">
        <ItemFade>
          <Link to="/cartogram" className="z-50 pointer-events-auto">
            Cartogram
          </Link>
        </ItemFade>
      </li>
      <li className="lg:text-center my-2 px-6 pt-1">
        <GetInTouchButton />
      </li>
    </ul>
  );

  const navDesktop = (
    <div className="container fixed inset-x-0 bottom-0 invisible lg:visible mx-auto min-w-fit w-1/2 my-10 p-2 rounded-full backdrop-blur-lg bg-background1/30 pointer-events-auto">
      <ul className="font-title font-bold text-white flex flex-col px-4 lg:flex-row mx-auto justify-evenly text-center leading-loose">
        <li className="lg:text-center my-2 bg-white/5 px-6 pt-1 rounded-full hover-animation">
          <ItemFade>
            <Link to={"/"}>Home</Link>
          </ItemFade>
        </li>
        <li className="lg:text-center my-2 bg-white/5 px-6 pt-1 rounded-full hover-animation">
          <ItemFade>
            <Link to="#apps">Apps</Link>
          </ItemFade>
        </li>
        <li className="lg:text-center my-2 bg-white/5 px-6 pt-1 rounded-full hover-animation">
          <ItemFade>
            <Link to="#partners">Partners</Link>
          </ItemFade>
        </li>
        <li className="lg:text-center my-2 bg-white/5 px-6 pt-1 rounded-full hover-animation">
          <ItemFade>
            <Link to="/blog">Blog</Link>
          </ItemFade>
        </li>
        <li className="lg:text-center my-2 px-6 pt-1 hover-animation">
          <GetInTouchButton />
        </li>
      </ul>
    </div>
  );

  const navbarMobile = (
    <div className="lg:hidden w-full fixed flex justify-between items-center mx-auto p-6 bg-background1/30 backdrop-blur-lg pointer-events-auto">
      <img
        src={logo}
        className="object-contain h-12 md:h-20 px-4 md:px-8"
        alt="Round Tower Indie App Studio Logo"
      />

      <button className="h-30 w-30" onClick={() => setOpen(true)}>
        <img src={menu_open} alt={"Menu Icon"} />
      </button>
    </div>
  );

  return (
    <nav className="fixed h-full w-full z-30 pointer-events-none">
      {isOpen && (
        <NavTransition
          className="lg:hidden fixed h-full w-full z-40 bg-background1/30 backdrop-blur-lg"
          pose={isOpen ? "visible" : "hidden"}
          initialPose={"hidden"}>
            
          <div className="flex flex-col h-screen justify-evenly pointer-events-auto">
            <img
              src={logo}
              className="h-20 mx-auto my-8"
              alt="Round Tower Indie App Studio Logo"
            />

            {navMobile}

            <ItemFade>
              <Social />
            </ItemFade>

            <div className="flex">
              <button
                className="h-30 z-50 mx-auto p-4 rounded-2xl bg-white/50 pointer-events-auto"
                onClick={() => setOpen(false)}>
                <img src={menu_close} alt={"Close Menu Button"} />
              </button>
            </div>
          </div>
        </NavTransition>
      )}

      {navbarMobile}

      {navDesktop}
    </nav>
  );
}

Nav.propTypes = {};

Nav.defaultProps = {};
