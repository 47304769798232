import React, { Suspense, Fragment } from "react";

import loadable from "@loadable/component";

import Seo from "../components/seo/seo";
import Nav from "../components/nav/nav";
import Hero from "../components/hero/hero";

const Apps = loadable(() => import("../components/apps/apps"));
const Partners = loadable(() => import("../components/partners/partners"));
const RecentClient = loadable(() =>
  import("../components/recent-client/recent-client")
);
const WorkWithUs = loadable(() =>
  import("../components/work-with-us/work-with-us")
);
const Footer = loadable(() => import("../components/footer/footer"));

const Index = ({ data }) => (
  <Fragment>
    <Nav />
    <Hero />
    <Suspense>
      <Apps />
      <Partners />
      <RecentClient />
      <WorkWithUs />
      <Footer />
    </Suspense>
  </Fragment>
);

export default Index;

export const Head = () => {
  return <Seo />;
};
