import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export default function GetInTouchButton() {
  const data = useStaticQuery(graphql`
    query WorkWithUsQuery {
      site {
        siteMetadata {
          typeformLink
        }
      }
    }
  `);

  return (
    <OutboundLink
      href={data.site.siteMetadata.typeformLink}
      target="_blank"
      rel="noreferrer"
      className="font-main mx-auto font-semibold my-2 bg-white text-background1 px-10 py-2 rounded-full text-center pointer-events-auto">
      Get in Touch
    </OutboundLink>
  );
}
